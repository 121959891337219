import styled from 'styled-components'
import '../../App.css'

const HeaderDropdown = props => {
  const {
    headerDropdownVisible,
    headerDropdownValues,
    headerDropdownSelection,
    handleChangeHeaderDropdown
  } = props

  const dropdownRows = headerDropdownValues.map(el => {
    let dynamicStyle = null
    if (el === headerDropdownSelection)
      dynamicStyle = {
        background: 'rgba(0, 192, 243, 1)',
        color: 'rgba(255, 255, 255, 1)'
      }
    return (
      <div
        key={el}
        className='header-dropdown-item'
        onClick={e => handleChangeHeaderDropdown(e, el)}
        style={dynamicStyle}
      >
        <p className='header-dropdown-font-size'>{el}</p>
      </div>
    )
  })

  return (
    <Wrapper filterDropdownVisible={headerDropdownVisible}>
      {dropdownRows}
    </Wrapper>
  )
}

export default HeaderDropdown

const Wrapper = styled.div`
  position: absolute;
  top: 30px;
  width: 155px;
  background: #ffffff;
  border: 1px solid rgba(106, 115, 123, 0.8);
  z-index: 100;
  display: ${props => (props.filterDropdownVisible ? '' : 'none')};

  .header-dropdown-item {
    transition: all 0.3s;
    width: 100%;
    padding: 6px;
    &:hover {
      cursor: pointer;
    }
  }

  .header-dropdown-item-highlighted {
    background: rgba(0, 192, 243, 1);
    color: rgba(255, 255, 255, 1);
  }

  .header-dropdown-font-size {
    font-size: 14px;
  }
`
