import LoginPage from './pages/LoginPage'
import DashboardPage from './pages/DasbboardPage'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

const App = () => {
  return (
    <BrowserRouter basename='/'>
      <Wrapper>
        <Routes>
          <Route exact path='/' element={<LoginPage />} />
          <Route exact path='/dashboard/:id' element={<DashboardPage />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  )
}

const Wrapper = styled.div`
  letter-spacing: 0.05rem;
  color: rgba(45, 42, 38, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 100vh;
  width: 100vw;
`

export default App
