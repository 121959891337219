import React, { useContext, useEffect, useReducer } from 'react'
import reducer from '../reducers/pagination_reducer'
import { useFilterContext } from './filter_context'

const initialState = {
  paginatedResult: [],
  curPage: 1,
  totalPages: 1,
  resultsPerPage: 50,
  pageInputValue: 1,
  totalRecords: 0
}

const PaginationContext = React.createContext()

export const PaginationProvider = ({ children }) => {
  const { filteredResult } = useFilterContext()

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    setPaginatedResult(1)
    dispatch({
      type: 'SET_CURPAGE',
      payload: 1
    })
  }, [filteredResult])

  const setPaginatedResult = curPage => {
    const totalRecords = filteredResult.length
    const totalPages = Math.ceil(filteredResult.length / state.resultsPerPage)
    let paginatedResult = []
    if (filteredResult.length > 0) {
      paginatedResult = filteredResult.slice(
        state.resultsPerPage * (curPage - 1),
        state.resultsPerPage * curPage
      )
    }
    dispatch({
      type: 'SET_PAGINATED_RESULT',
      payload: { totalPages, paginatedResult, totalRecords }
    })
  }

  const toNextPage = curPage => {
    if (curPage < state.totalPages) curPage += 1
    else curPage = 1
    dispatch({
      type: 'SET_CURPAGE',
      payload: curPage
    })
    setPaginatedResult(curPage)
  }

  const toPrevPage = curPage => {
    if (curPage > 1) curPage -= 1
    else curPage = state.totalPages
    dispatch({
      type: 'SET_CURPAGE',
      payload: curPage
    })
    setPaginatedResult(curPage)
  }

  const setCurPage = e => {
    const curPage = e.target.value
    if (curPage > 0 && curPage <= state.totalPages) {
      dispatch({
        type: 'SET_CURPAGE',
        payload: curPage
      })
    }
    dispatch({
      type: 'SET_PAGE_INPUT_VALUE',
      payload: curPage
    })
  }

  return (
    <PaginationContext.Provider
      value={{
        ...state,
        toNextPage,
        toPrevPage,
        setCurPage
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}

export const usePaginationContext = () => {
  return useContext(PaginationContext)
}
