import styled from 'styled-components'
import '../../App.css'

const ListRowIcon = props => {
  return <Wrapper>{props.icon}</Wrapper>
}

export default ListRowIcon

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .icon-square-red {
    background-color: rgba(172, 30, 40, 1);
    border: 1px solid rgba(172, 30, 40, 1);
    color: rgba(255, 255, 255, 1);
  }

  .icon-square-grey {
    color: rgba(190, 192, 194, 1);
    border: 1px solid rgba(190, 192, 194, 1);
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      color: rgba(172, 30, 40, 1);
    }
  }
`
