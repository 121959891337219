import React, { useContext, useReducer, useEffect } from 'react'
import reducer from '../reducers/submenu_reducer'
import dashboardData from '../data/dashboardData'
import dashboardMenuData from '../data/dashboardMenuData'

const initialState = {
  subMenuVisible: false,
  submenuData: [],
  curSubmenuName: ''
}

const SubmenuContext = React.createContext()

export const SubmenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', payload: dashboardData })
  }, [dashboardData])

  const toggleVisibility = e => {
    const curSubmenuName = e.target.name
    const submenuData = dashboardMenuData[curSubmenuName]
    let subMenuVisible = !state.subMenuVisible
    if (subMenuVisible)
      dispatch({
        type: 'TOGGLE_VISIBILITY_ON',
        payload: { submenuData, subMenuVisible, curSubmenuName }
      })
    else
      dispatch({
        type: 'TOGGLE_VISIBILITY_OFF',
        payload: { subMenuVisible }
      })
  }

  return (
    <SubmenuContext.Provider
      value={{
        ...state,
        toggleVisibility
      }}
    >
      {children}
    </SubmenuContext.Provider>
  )
}

export const useSubmenuContext = () => {
  return useContext(SubmenuContext)
}
