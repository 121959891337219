const pagination_reducer = (state, action) => {
  if (action.type === 'SET_PAGINATED_RESULT') {
    const { totalPages, paginatedResult, totalRecords } = action.payload
    return { ...state, totalPages, paginatedResult, totalRecords }
  }
  if (action.type === 'SET_CURPAGE') {
    return { ...state, curPage: action.payload }
  }
  if (action.type === 'SET_PAGE_INPUT_VALUE') {
    return { ...state, pageInputValue: action.payload }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default pagination_reducer
